<template>
  <div class="overflow-hidden bg-white py-32">
    <div class="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
      <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
        <div class="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
          <h2 class="text-3xl font-bold tracking-tight text-left text-gray-900 sm:text-4xl">Teaching at PVIS</h2>
          <p class="mt-6 text-xl text-left leading-8 text-gray-600">Today’s learning for tomorrow’s world — let’s create tomorrow together.</p>
          <p class="mt-6 text-base text-left leading-7 text-gray-600">Port Vila International School provides internationally recognised, quality education in a welcoming and inclusive community from Early Education to Year 12. Together, we share a love of learning recognising the potential of every individual. We nurture a connected community through collaboration and communication and by genuinely investing in each other. We embrace diversity, different perspectives and foster an authentic celebration of different cultures. Our student-centred approach to teaching and learning supports and encourages student-voice to empower global citizens who aspire to a better future. By providing diverse opportunities that meet individual needs, we’re developing courageous future leaders for Vanuatu and the world that we want.</p>

          <p class="mt-6 font-bold text-base text-left leading-7 text-gray-600">The Unique PVIS Opportunity</p>

          <ul class="mt-6 text-base text-left leading-7 text-gray-600">
            <li>- Vanuatu is a unique place with a distinctive energy and character.</li>
            <li>- Be part of a mission-driven organisation and enjoy the freedom and independence to engage in new learning methods, projects and experiment with your ideas.</li>
            <li>- Contribute to Vanuatu’s national vision to become, stable, sustainable and prosperous.</li>
            <li>- Be a member of an aspirational, intellectual, and vibrant community of professionals.</li>
          </ul>

        <p class="mt-6 text-base text-left leading-7 text-gray-600">Please get in touch if you are available for work and are a qualified teacher: employment@pvis.edu.vu </p>
<div class="mt-10 flex">
            <a href="mailto:employment@pvis.edu.vu?subject=Join Our Team" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Join our team <span aria-hidden="true">&rarr;</span></a>
          </div>
        </div>

        
        <div class="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
          <div class="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
            <img :src="pvis3" alt="" class="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover" />
          </div>
          <div class="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
            <div class="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
              <img :src="pvis2" alt="" class="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover" />
            </div>
            <div class="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
              <img :src="pvis1" alt="" class="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover" />
            </div>
            <div class="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
              <img :src="pvis4" alt="" class="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover" />
            </div>
          </div>
        </div>
       
        
      </div>

    </div>
    <div class="mx-auto max-w-7xl px-6 lg:flex lg:px-8 flex flex-wrap lg:flex-nowrap">
      <h2 class="text-3xl pt-12 font-bold tracking-tight text-left text-gray-900 sm:text-4xl">Current Employment Opportunities</h2>

    </div>
    <div class="mx-auto max-w-7xl px-6 lg:grid lg:grid-cols-2 lg:px-8 flex flex-wrap">

    <!-- <div class="lg:col-span-1 lg:w-1/2 lg:pb-8 mx-auto">
      <p class="mt-6 font-bold text-base text-left leading-7 text-gray-600">Senior Secondary Mathematics, Physics and English teacher vacancies  </p>

      <p class="mt-6 text-base text-left leading-7 text-gray-600">We are seeking passionate and adaptable Mathematics, Physics and English teachers to join our team in  2024. Experience teaching senior secondary students is essential and while knowledge of the SACE curriculum is an advantage, it is not a requirement.</p>

      <p class="mt-6 text-base text-left leading-7 text-gray-600">All applicants must be registered to teach in an Australian or New Zealand Registration Board or have the ability to be registered.</p>

      <p class="mt-6 text-base text-left leading-7 text-gray-600">If you are interested in exploring an opportunity to teach at PVIS in 2024 we would be happy to receive your Application Form  and cover letter by emailing employment@pvis.edu.vu</p>

      <div class="mt-10 flex">
        <a href="mailto:employment@pvis.edu.vu?subject=Current Employment Opportunities" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Join our team <span aria-hidden="true">&rarr;</span></a>
      </div>
  </div> -->

<!--   <div class="lg:col-span-1 lg:w-full lg:pb-8">
    <h3 class="mt-6 font-bold text-2xl text-left leading-7 text-gray-600">Director of Finance</h3>

    <p class="mt-6 text-base text-left leading-7 text-gray-600">Join PVIS as the Director of Finance! This senior leadership role is crucial in managing our school’s financial health and operations. You will oversee comprehensive financial management and ensure all statutory and compliance obligations are met efficiently and ethically. Reporting directly to the Principal, you will play a key role in shaping the school's strategic vision and enhancing our financial performance. We seek a leader with a head for finances but a heart for education, possessing strong cultural acumen and a commitment to our values. Your ability to foster high-quality, professional relationships with various stakeholders will guide informed decision-making that impacts the school community. Join us in supporting the goals and values of PVIS, where your leadership will make a significant difference in the lives of our students and their families.</p>

    <p class="mt-6 text-base text-left leading-7 text-gray-600">This is a full-time role with an initial three-year contract. The start date is negotiable, but the desirable start date is July 2024. For more information about the role, contact principal@pvis.edu.vu</p>
    <div class="mt-10 flex">
      <a href="mailto:principal@pvis.edu.v?subject=[WEBSITE EMAIL] Director of Finance" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Join our team <span aria-hidden="true">&rarr;</span></a>
    </div>
  </div> -->

  <!-- <div class="lg:col-span-1 lg:w-full lg:pb-8 lg:mx-8">
    <h3 class="mt-6 font-bold text-2xl text-left leading-7 text-gray-600">Deputy Principal</h3>
    <p class="mt-6 text-base text-left leading-7 text-gray-600">We are currently seeking a Deputy Principal for an initial 3-year contract.</p>

    <p class="mt-6 text-base text-left leading-7 text-gray-600">The Deputy Principal will play a vital role in providing educational leadership across the entire school. You will be instrumental in developing and operationalizing our educational framework. Your leadership will be pivotal in enhancing our curriculum, learning, and teaching methodologies. Curriculum design and leading teaching and learning are key focuses of this role.</p>

    <p class="mt-6 text-base text-left leading-7 text-gray-600">As a key member of the senior leadership team, we are looking for a leader who is passionate about education and committed to fostering a positive and dynamic learning environment. The ideal candidate will be able to lead in a multicultural environment, as we are a diverse international school in the Pacific context.</p>

    <p class="mt-6 text-base text-left leading-7 text-gray-600">The start date is negotiable, with the candidate able to take up the role in 2024 or at the beginning of 2025. If you are ready to make a significant impact on our school community, we would love to hear from you.</p>

    <p class="mt-6 text-base text-left leading-7 text-gray-600">For more information about the role, please contact principal@pvis.edu.vu</p>
    <div class="mt-10 flex">
      <a href="mailto:principal@pvis.edu.vu?subject=[WEBSITE EMAIL] Deputy Principal" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Join our team <span aria-hidden="true">&rarr;</span></a>
    </div>
  </div> -->

  <div class="lg:col-span-1 lg:w-full lg:pb-8">
    <h3 class="mt-6 font-bold text-2xl text-left leading-7 text-gray-600">Teaching Positions 2025</h3>

    <p class="mt-6 text-base text-left leading-7 text-gray-600">We are currently seeking passionate and dedicated Primary and Secondary teachers to join our team at Port Vila International School and work across our middle and senior schools. We have openings for various teaching positions and are particularly interested in hearing from teachers who can teach Senior Maths and Science, Physical Education across the school, French as a foreign language, and Art. Successful candidates will begin in January 2025, with some positions able to begin later in 2024.</p>

    <p class="mt-6 text-base text-left leading-7 text-gray-600">We are currently seeking passionate and dedicated Primary and Secondary teachers to join our team at Port Vila International School and work across our middle and senior schools. We have openings for various teaching positions and are particularly interested in hearing from teachers who can teach Senior Maths and Science, Physical Education across the school, French as a foreign language, and Art. Successful candidates will begin in January 2025, with some positions able to begin later in 2024.</p>

    <p class="mt-6 text-base text-left leading-7 text-gray-600">Our students are incredible, and we are looking for teachers who feel aligned with our school values and believe in an equitable future. Ideal candidates will have a high level of energy, the tenacity to persevere, and the ability to improvise and create quickly and creatively. We seek educators who want to work in a multicultural international school in the Pacific context.</p>

    <p class="mt-6 text-base text-left leading-7 text-gray-600">If you are ready to make a significant impact on our students' education and be part of a vibrant school community, we would love to hear from you. For more information about the roles and how to apply, please contact employment@pvis.edu.vu</p>

    <p class="mt-6 text-base text-left leading-7 text-gray-600">Join us at PVIS and contribute to our mission of excellence in education!</p>
    <div class="mt-10 flex">
      <a href="mailto:employment@pvis.edu.vu?subject=[WEBSITE EMAIL] Teaching Positions 2025" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Join our team <span aria-hidden="true">&rarr;</span></a>
    </div>
  </div>

  <div class="lg:col-span-1 lg:w-full lg:pb-8 lg:mx-8">
    <h3 class="mt-6 font-bold text-2xl text-left leading-7 text-gray-600">Casual Relief Teaching Staff</h3>

    <p class="mt-6 text-base text-left leading-7 text-gray-600">PVIS is always looking for qualified teachers (Early Childhood, Primary and Secondary), familiar with the Australian, New Zealand or International schooling context, who hold a minimum of a degree and further qualifications in Education to join our register of casual relief teachers. Please note we are only able to recruit international teachers on a casual relief basis who are currently living in Vanuatu (or relocating here for another reason, such as accompanying a partner).</p>

    <p class="mt-6 text-base text-left leading-7 text-gray-600">Please get in touch if you are available for work and are a qualified teacher: employment@pvis.edu.vu </p>
    <div class="mt-10 flex">
      <a href="mailto:employment@pvis.edu.vu?subject=[WEBSITE EMAIL] Casual Relief Teaching Staff" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Join our team <span aria-hidden="true">&rarr;</span></a>
    </div>
  </div>

</div>
  </div>
</template>



<script setup>
import { ref } from 'vue';
import PVIS1 from '@/assets/documents/PVIS1.jpg';
import PVIS2 from '@/assets/documents/PVIS2.jpg';
import PVIS3 from '@/assets/documents/PVIS3.jpg';
import PVIS4 from '@/assets/documents/PVIS4.jpg';

const pvis1 = ref(PVIS1);
const pvis2 = ref(PVIS2);
const pvis3 = ref(PVIS3);
const pvis4 = ref(PVIS4);


</script>